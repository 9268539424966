import $ from "jquery";

$(document).ready(() => {
  let $videoBlock = $(".video-container");
  let $video = $(".video-container iframe");

  let isMobile = window.matchMedia("(max-width: 768px)").matches;

  if (!$videoBlock.length || !$video.length) {
    return;
  }

  $video.css("width", "100%");
  $video.css("max-width", "auto");

  let initialWidth = isMobile? "60vw" : "50vw"; // The initial width of the video
  let maxWidth = "100vw"; // The maximum width the video will reach
  let growDistance = 250; // The distance over which the video will grow to 100%
  let windowHeight = $(window).height(); // The window height to calculate midpoint

  // Set initial styles
  $videoBlock.css({
    width: initialWidth,
    position: "relative",
    paddingBottom: "28.125vw",
    margin: "auto",
    height: isMobile ? "auto" : "auto",
  });
 

  $(window).scroll(function () {
    let scrollTop = $(window).scrollTop();
    let videoTop = $videoBlock.offset().top;
    // let videoHeight = $videoBlock.height();

    // Calculate when the video reaches the middle of the screen
    let triggerPoint = videoTop - windowHeight / 1.6;
    let scrollDistance = scrollTop - triggerPoint;
    if (scrollDistance > 0) {
      // If scrolling past the midpoint of the video
      let progress = Math.min(scrollDistance / growDistance, 1); // Progress between 0 and 1
      let newWidthPercentage = 50 + progress * 50; // Width changes from 50% to 100%
      let newHeight = (newWidthPercentage * 9) / 16; // Calculate height based on 16:9 aspect ratio
      $videoBlock.css("will-change", "width , height");

      $videoBlock.css({
        width: newWidthPercentage + "vw",
        paddingBottom: newHeight + "vw",
        position: "sticky",
        top: "0",
        "z-index": "10",
      });
      if (newWidthPercentage >= 100) {
        // Once the video reaches 100%, return to normal scrolling
        $videoBlock.css({
          position: "relative",
          width: maxWidth,
        });
      }
    } else {
      // If not enough scroll to fix the video
      $videoBlock.css({
        width: initialWidth,
        paddingBottom: `${(initialWidth * 9) / 16}vw`, // Reset height
        position: "relative",
      });
    }
  });
});
